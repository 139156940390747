import { GoogleLogin as ReactGoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";



function GoogleLogin({onSuccess,onError,context}){

    useEffect(()=>{
       setTimeout(() => {
        const googleButton = document.getElementById("googleLoginButton");   
        if(googleButton && googleButton.getElementsByTagName("svg")){
          const svgContainer = googleButton.getElementsByTagName("svg")[0].parentElement;
          svgContainer.style.width="38px";
          svgContainer.style.height="38px";
        }
       }, 0);
    },[])

    return (<ReactGoogleLogin
    type="icon"
    shape="circle"
    ux_mode="popup"
    onSuccess={onSuccess}
    onError={onError}
    context={context}
    theme="outline"
    size="large"
    logo_alignment="center"
    containerProps={{
        id:"googleLoginButton"
    }}
  />)
}



export default GoogleLogin