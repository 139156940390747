
const STATUS ={
  PENDING: "PENDING",
  REGISTERED: "REGISTERED",
  SUBMITTED:"SUBMITTED",
  VERIFIED:"VERIFIED",
}

const CATEGORIES ={
  HEALTH: "HEALTH",
}

const SESSION_KEYS = {
  PASSPORT_TOKEN:"PASSPORT_TOKEN",
  TENANTID: "PASSPORT_TENANTID",
  SELL: "SELL",
  COLLAB: "COLLABORATE",
  KEEP: "KEEP",
  LICENSE:  "LICENSE",
};

const ENTITIES = {
  IDEA: "IDEA",
  PROFILE: "PROFILE",
  CLAN: "CLAN",
  PUBLIC: "PUBLIC",
};

const TRANSACTION_TYPES = {
  PURCHAE_GOLD: "PURCHAE_GOLD",
  DISTRIBUTE: "DISTRIBUTE",
  REDEEM: "REDEEM"
};

const CONSTANTS = {
  CATEGORIES,
  STATUS,
  TRANSACTION_TYPES
};
export default CONSTANTS