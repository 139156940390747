import { useCallback, useState } from "react";
import { Button } from "../../commons/Button";
import { CustomNavLinkSmall, Span } from "./styles";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Login from "../Login";

type MenuItemPropsTypes={
    onChangeVisibility:(param:boolean)=>void
}

const MenuItem = ({onChangeVisibility}:MenuItemPropsTypes) => {
  const [showLoginModal,setShowLoginModal] = useState(false);
  const location = useLocation();
  const isCorporateLogin = location.pathname.includes("/corporate/login");

  const {t} = useTranslation();
  
  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
    onChangeVisibility(false);
  };

  const openLoginModal = useCallback(()=>{
    setShowLoginModal(true)
  },[])


  const closeLoginModal = useCallback(()=>{
    setShowLoginModal(false)
  },[])


  return (
    <>
      <CustomNavLinkSmall onClick={() => scrollTo("about")}>
        {/* <Span>{t("About")}</Span> */}
      </CustomNavLinkSmall>
      <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
        {/* <Span>{t("Mission")}</Span> */}
      </CustomNavLinkSmall>
      <CustomNavLinkSmall onClick={() => scrollTo("product")}>
       <Link to="/tokenomics">
          Whitepaper
       </Link>
      </CustomNavLinkSmall>
      {!isCorporateLogin && <CustomNavLinkSmall
        style={{ width: "180px" }}
        onClick={openLoginModal}
      >
        <Span>
          <Button>{t("Login")}</Button>
        </Span>
      </CustomNavLinkSmall>}
      <Login open={showLoginModal} onCloseModal={closeLoginModal}/>
    </>
  );
};


export default MenuItem