import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import MenuItem from "./MenuItem";

import { withTranslation, TFunction } from "react-i18next";
import Container from "../../commons/Container";

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  Label,
  Outline,
  Title,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
 
  const toggleButton = () => {
    setVisibility(!visible);
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between" align="middle">
          <LogoContainer to="/" aria-label="homepage">
          <Title >{t("IdeaTribe")}</Title>
          </LogoContainer>
          <NotHidden>
            <MenuItem onChangeVisibility={setVisibility} />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem  onChangeVisibility={setVisibility} />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
