import axios from "axios";
import ENDPOINTS from "../commons/Endpoints";
import _ from "lodash";

const AxiosInstance = axios.create({
  baseURL:
  window.location.origin.indexOf("localhost") > -1 ? ENDPOINTS.LOCAL_ENDPOINTS : 
  window.location.origin.indexOf("test") > -1 ? ENDPOINTS.TEST_ENDPOINTS : ENDPOINTS.REMOTE_ENDPOINTS
});

AxiosInstance.interceptors.request.use(function(config) {
  console.log("config is here ------- ", config)
  if(_.isEmpty(config.headers["x-access-token"])){
    config.headers["x-access-token"] =  sessionStorage.getItem("PASSPORT_TOKEN");
  }
  return config;
});

AxiosInstance.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default AxiosInstance;



