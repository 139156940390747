const REMOTE_ENDPOINTS = "https://passport-server-abhijath-behurs-projects.vercel.app/api";
const TEST_ENDPOINTS = "https://test-passport-server-abhijaths-projects.vercel.app/api"
const LOCAL_ENDPOINTS = "http://localhost:4000/api";
const WEBSOCKET_ENDPOINT = "ws://localhost:4000/api";
const WEBSOCKET_REMOTE_ENDPOINT = "wss://"+window.location.host


const ENDPOINTS = {
    REMOTE_ENDPOINTS,
    LOCAL_ENDPOINTS,
    WEBSOCKET_ENDPOINT,
    WEBSOCKET_REMOTE_ENDPOINT,
    TEST_ENDPOINTS
}


export default ENDPOINTS