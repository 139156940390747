
import _ from "lodash";
import loadable from '@loadable/component';
import React, { useState } from "react";
import "antd/dist/antd.css";
import { ThemeProvider } from "@mui/material/styles";
import theme  from "./theme";
import { UserContext } from "./contexts/UserContext";
import ErrorBoundary from "./components/AppBoundary/AppBoundary"
import Page from "./components/Page";
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

import { GoogleOAuthProvider } from "@react-oauth/google";

const Console = loadable(()=>import(/* webpackChunkName: 'Console' */ "./Screens/Console/Console"));
const Dummy = loadable(()=>import(/* webpackChunkName: 'Dummy' */ "./Screens/Dummy/Dummy"));
const Entries = loadable(()=>import(/* webpackChunkName: 'Entries' */ "./Screens/Entries/Entries"));
const Home = loadable(()=>import(/* webpackChunkName: 'Home' */ "./Screens/Home"));

const Faq = loadable(()=>import(/* webpackChunkName: 'Faq' */ "./Screens/Faq"));
const About = loadable(()=>import(/* webpackChunkName: 'About' */ "./Screens/About"));
const CorporateLogin = loadable(()=>import(/* webpackChunkName: 'About' */ "./Screens/Auth/CorporateLogin"));
const Tokenomics = loadable(()=>import(/* webpackChunkName: 'About' */ "./Screens/Tokenomics"));







const App = (props) => {
  const [company, setCompany] = useState(0);
  return (
    <GoogleOAuthProvider clientId="639340003430-0gldnqd5a7tll38k5jpa7q6dqtv5c62v.apps.googleusercontent.com" >
    <Router>
      <ThemeProvider theme={theme}>
      <UserContext.Provider value={{company, setCompany}}>
        <div className="appContainer">
          <div className="app-content">
            <ErrorBoundary>

              <Switch>
                 <Route exact path="/console" component={Console} />
                <Route exact path="/entries" component={Entries} />
                <Route exact path="/dummy" component={Dummy} />
                <Page>
                  {/* <Route path="/login" render={(props) => <Login />} /> */}
                  <Route exact path="/faq" component={Faq}/>
                  <Route path="/corporate/login" component={CorporateLogin} />
                  <Route path="/tokenomics" component={Tokenomics} />
                  <Route exact path="/about" component={About}/>
                  <Route exact path="/" component={Home} />
                </Page>
               
              </Switch>
             
              {/* <div id="g_id_onload"
                data-client_id="639340003430-0gldnqd5a7tll38k5jpa7q6dqtv5c62v.apps.googleusercontent.com"
                data-context="signin"
                data-ux_mode="popup"
                data-callback="loginCallback"
                data-auto_prompt="false">
              </div> */}
              </ErrorBoundary>
          </div>
        </div>
        <ToastContainer/>
        </UserContext.Provider>
      </ThemeProvider>
    </Router>
    </GoogleOAuthProvider>
  );
};

export default withRouter(App);
