import * as React from "react";
import { useEffect } from "react";
import { Button, CssBaseline } from "@mui/material";
import Link from "@mui/material/Link";
import TextField from '../../commons/Input'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import AuthInterface from "../../Interfaces/AuthInterface";
import { showToaster } from "../../commons/common.utils";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

// import {GoogleIcon} from '../../assets'

import GoogleLogin from './GoogleLogin'
import _ from "lodash";


export default function LoginForm(props) {
  const {isCorporateLogin=false,onSuccess} = props;
  const [isPasswordVisible,setIsPasswordVisible] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [mailSent, setMailSent] = React.useState(false);
  const [hasInvite, setHasInvite] = React.useState(false);
  const [formData,setFormData]= React.useState({});
  const history = useHistory();


  
  useEffect(() => {
    // checkForSession();
  }, []);

  const checkForSession = () => {
    let token = sessionStorage.getItem("PASSPORT_TOKEN");
    AuthInterface.validate({ token })
      .then((success) => {
        history.push("/console");
      })
      .catch((err) => {});
  };


  const handleSubmit = (event) => {
    const submitData = {...formData}
    submitData["userType"] = isCorporateLogin ? "corporate" :"individual"
    let validation = validate(submitData);

    if (signUp) {
      if (mailSent) {
        AuthInterface.register(submitData).then(
          (success) => {
            console.log(success);
            onSuccess && onSuccess();
            sessionStorage.setItem("PASSPORT_TOKEN", success?.data?.token);
            history.push("/console");

          }
        );
      } else {
        if (!validation.valid) {
          setErrorMessage(validation.message);
          return;
        }
        setMailSent(true);
        alert(
          "Verification code will be sent to the mail address."
        );
        setErrorMessage("");
        AuthInterface.verify(submitData).then((success) => {
          if (success?.data.success) {
          } else {
            showToaster(
              "Sorry! We were unable to share you the verification code to the mail address",{
                type:"error"
              }
            );
          }
        });
      }
    } else {
      AuthInterface.login(submitData)
        .then((success) => {
          console.log("Login succesful");
          onSuccess && onSuccess();
          sessionStorage.setItem("PASSPORT_TOKEN", success?.data?.token);
          history.push("/console");

        })
        .catch((error) => {
          showToaster("Sorry! Please use valid credentials to login",{
            type:"error"
          });
        });
    }
  };

  const validate = (submitData) => {
    let valid = true;
    let message = "";
    if (
      !String(submitData.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      valid = false;
      message = "Invalid mail ID";
    }
    if (
      !String(submitData.password).match(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
      )
    ) {
      valid = false;
      message =
        "Password should have atleast one numner and one special charecter";
    }
    if (
      (!submitData.companyName || submitData.companyName.length === 0) &&
      submitData.userType === "corporate"
    ) {
      valid = false;
      message = "Please provide the company name";
    }
    return {
      valid,
      message,
    };
  };


  const setPasswordVisible = React.useCallback(()=>{
    setIsPasswordVisible(true)
  },[])

  const setPasswordInVisible = React.useCallback(()=>{
    setIsPasswordVisible(false)
  },[])

  const onChange=(e)=>{
    const {name,value} = e.target;
    setFormData((prev)=>({...prev,[name]:value}))
  }


  return (
      <Grid
        container
        component="main"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection:"column"
        }}
      >
        <CssBaseline />
        <Typography component="h1" variant="h5">
          {isCorporateLogin ? "CORPORATE":"CONSUMER" }{signUp ? " REGISTER" : " LOGIN"}
        </Typography>
        <Box
          component="form"
          sx={{ mt: 1,width:"100%" }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            size="small"
            id="email"
            placeholder="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            disabled={mailSent}
            value={formData.email || ""}
            onChange={onChange}
            InputProps={{
              startAdornment:<PersonOutlineOutlinedIcon/>,
              margin:"0 8px"
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            size="small"
            name="password"
            value={formData.password || ""}
            onChange={onChange}
            placeholder="Password"
            type={isPasswordVisible ? "text":"password"}
            id="password"
            autoComplete="current-password"
            disabled={mailSent}
            InputProps={{
              startAdornment:<LockOutlinedIcon marginRight={"8px"}/>,
              endAdornment:isPasswordVisible?<VisibilityOffOutlinedIcon onClick={setPasswordInVisible}/>:<RemoveRedEyeOutlinedIcon onClick={setPasswordVisible}/>,
              margin:"0 8px"
            }}
          />
            {hasInvite && <TextField
            margin="normal"
            fullWidth
            id="referredBy"
             size="small"
             value={formData.referredBy||""}
             onChange={onChange}
            label="Invite code"
            name="referredBy"
            autoComplete="referredBy"
            autoFocus
            disabled={mailSent}
          />}
          {signUp && isCorporateLogin ? (
            <TextField
              margin="normal"
              required
              fullWidth
              size="small"
              value={formData.companyName||""}
              onChange={onChange}
              name="companyName"
              placeholder="Company Name"
              id="companyName"
              disabled={mailSent}
            />
          ) : (
            <div></div>
          )}
          {mailSent && (
            <TextField
              margin="normal"
              required
              fullWidth
              size="small"
              value={formData.verificationCode||""}
              onChange={onChange}
              name="verificationCode"
              label="Verification Code"
              id="verificationCode"
              autoComplete="Verification Code"
            />
          )}
          <Button
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
            }}
            onClick={handleSubmit}
          >
            {signUp ? (mailSent ? "Register" : "Verify") : "Login"}
          </Button>
          <Grid container style={{display:"flex", justifyContent:"space-between"}}>
            <Grid item style={{ cursor: "pointer" }}>
              {signUp ? (
                <Link
                  onClick={() => {
                    setSignUp(false);
                    setFormData({});
                    setMailSent(false)
                  }}
                  variant="body2"
                >
                  {"Already have an account? Login"}
                </Link>
              ) : (
                <Link
                  onClick={() => {
                    setSignUp(true);
                    setFormData({});
                  }}
                  variant="body2"
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              )}
            </Grid>
            {signUp && !hasInvite && !mailSent && <Grid item>
              <Link onClick={()=>{
                setHasInvite(true)
              }} variant="body2">
                Got invite code?
              </Link>
            </Grid>}
          </Grid>
          <Grid container>
            <Grid item xs>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Grid>
          </Grid>
        </Box>
        <Box  marginTop={"20px"} paddingTop={"8px"} borderTop={"2px solid black"}>
         
         <GoogleLogin  
          onSuccess={(element)=>{
            console.log(element)
          }}
          onError={()=>{
            console.logo("error")
          }}

          context={signUp ? "signup":"signin"}
         />
         
        </Box>
    </Grid>
  );
}