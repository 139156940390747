import * as React from "react";

import { Modal } from "antd";
import LoginForm from "./LoginForm";



export default function Login(props){ 
  const {open,onCloseModal} = props;

  return <Modal open={open} footer={null} className="modal-props" onCancel={onCloseModal} destroyOnClose>
    <LoginForm props={props} onSuccess={onCloseModal} />
  </Modal>
}