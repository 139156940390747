import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import { PageContainer } from "./style";

export default function Page({children}:{children:React.ReactNode}){
    return <div>
        <div>
            <Header/>
        </div>
        <PageContainer>
            {children}
        </PageContainer>
        <div>
            <Footer/>
        </div>
    </div>
}