import { withTranslation } from "react-i18next";
import { Container, StyledInput } from "./styles";
import { Label } from "../TextArea/styles";
import { InputProps } from "../types"

const Input = ({ label, name,placeholder,value, onChange, t,...restProps }: InputProps) => (
  <Container>
    {label&&<Label htmlFor={name}>{t(label)}</Label>}
    <StyledInput
      placeholder={t(placeholder)}
      name={name}
      value={value}
      id={name}
      onChange={onChange}
      {...restProps}
    />
  </Container>
);

export default withTranslation()(Input);
